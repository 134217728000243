@layer base {
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('../../public/fonts/GIP-Regular.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url('../../public/fonts/GIP-RegularItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('../../public/fonts/GIP-Thin.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url('../../public/fonts/GIP-ThinItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url('../../public/fonts/GIP-Light.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 200;
        font-display: swap;
        src: url('../../public/fonts/GIP-LightItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url('../../public/fonts/GIP-UltraLight.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url('../../public/fonts/GIP-UltraLightItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('../../public/fonts/GIP-Medium.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url('../../public/fonts/GIP-MediumItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('../../public/fonts/GIP-SemiBold.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 600;
        font-display: swap;
        src: url('../../public/fonts/GIP-SemiBoldItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('../../public/fonts/GIP-Bold.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url('../../public/fonts/GIP-Bold.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 800;
        font-display: swap;
        src: url('../../public/fonts/GIP-ExtraBold.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 800;
        font-display: swap;
        src: url('../../public/fonts/GIP-ExtraBoldItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url('../../public/fonts/GIP-Black.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url('../../public/fonts/GIP-BlackItalic.otf') format('opentype');
    }
    html {
        font-family: 'GIP';
        font-optical-sizing: auto;
        font-weight: 500;
        font-style: normal;
        font-variation-settings: "slnt" 0;
    }
}

$bgColor: white;
$textColor: #222222;
$gip: 'GIP';
$opacity: 0.6;
$colorWithOpacity: rgba($textColor, $opacity);


body {
    margin: 0;
    background-color: $bgColor;
    font-family: $gip;
    -webkit-font-smoothing: antialiased;
}

a {
    color: inherit !important;
    text-decoration: none !important;
}

.aboutMore {
    text-align: center;
    padding: 60px 50px;
    transition: .5s;
    
    .logo {
        img {
            width: 120px;
            flex-shrink: 0;
        }
    }
    .slogan {
        font-size: 38px;
        font-family: $gip;
        padding: 10px 0px;
        line-height: 48px;
        font-weight: 400;
        font-style: normal;
        color: #222222;
        max-width: 681px;
        margin: auto;
    }
    .description {
        font-size: 18px;
        font-family: $gip;
        padding-top: 10px;
        line-height: 22px;
        font-weight: 600;
        color: #222222;
        max-width: 320px;
        margin: auto;
    }
    .small_logos {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .section {
            margin: 20px 40px;
            display: flex;

            .loader_text {
                display: inline-block;
                margin-left: 30px;
                font-size: 20px;
                align-self: self-start;
                font-family: $gip;
            }
        }
    }
    .partners {
        font-weight: 400;
        padding-top: 80px;
        display: flex;
        justify-content: center;
        gap: 80px;
        .singlePartner {
            .type {
                font-size: 18px;
                font-family: $gip;
                font-weight: 600;
                color: #222222;
                line-height: 22px;
            }
            .logo {
                margin: 20px 0px;
                img {
                    width: 262px;
                    flex-shrink: 0;
                }
            }
        }
    }
    .timeline {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 120px;
        margin-left: 60px;
        align-items: start;
        color: #222222;
        font-family: $gip;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        .title-cont {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .amarlogo {
            margin-right: 7px;
            img {
                width: 120px;
                flex-shrink: 0;
            }
        }
        .date {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: $colorWithOpacity;
            margin-left: 5px;
        }
        .dashedLine {
            border-left: 1px dashed #0057D8;
            height: 23px;
            margin: 14px 13px;
        }
    }
    .contactUs {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 50px;
        gap: 4px;
        font-family: $gip;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #222222;
        span {
            font-weight: 600;
        }
    }
}

.comingSoon {
    margin-right: 20px;
}

.loader {
    display: inline-block;
    color: #14153B;
    font-size: 20px;
    gap: 20px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 0px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes load6 {
0%   {box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;}
5%  {box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;}
10%  {box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;}
20%  {box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;}
38% {box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;}
59%  {box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;}
95%  {box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;}
100% {box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;}
}
@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

canvas {
    display: block;
    vertical-align: bottom;
} 
#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $bgColor;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -100;
} 
.count-particles {
    background: #000022;
    position: absolute;
    top: 48px;
    left: 0;
    width: 80px;
    color: #13e8e9;
    font-size: 0.8em;
    text-align: left;
    text-indent: 4px;
    line-height: 14px;
    padding-bottom: 2px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
}
.js-count-particles {
    font-size: 1.1em;
}
#stats, .count-particles {
    -webkit-user-select: none;
    margin-top: 5px;
    margin-left: 5px;
}
#stats {
    border-radius: 3px 3px 0 0;
    overflow: hidden;
}
.count-particles {
    border-radius: 0 0 3px 3px;
}

@import "phone";