@media only screen and (max-width: 768px) {
    .aboutMore {
        .small_logos {
            flex-direction: column;
            gap: 30px;
            .section {
                padding-left: 0px !important;
            }
        }
        .partners {
            gap: 40px;
            .singlePartner .type {
                font-size: 16px;
            }
        }
        .slogan {
            font-size: 28px;
            line-height: 36px;
        }
        .description {
            font-size: 16px;
        }
        .timeline {
            margin-left: 0px;
            justify-content: center;
            align-items: center;
            .title-cont {
                flex-direction: column;
                font-size: 16px;
                justify-content: center;
            }
            .amarlogo {
                img {
                    width: 100px;
                }
            }
            .date {
                font-size: 16px;
            }
            .circle {
                width: 20px;
                height: 20px;
            }
        }
        .contactUs {
            font-size: 16px;
            justify-content: center;
        }
        .comingSoon {
            margin-right: 10px;
        }
    }
}